import "../../core/src/components/blocksuite/block-suite-editor/bi-directional-link-panel.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/blocksuite/block-suite-editor/bi-directional-link-panel.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA5VWTW/bMAy951cIAwbUwxQ4btq17goM22W3/YBhB9qiHa2yZEiyk2zIfx8sK47tKk12Kkrx4/Hxkc4yw8ps25j8XRCy5cxuUrKK4/dPC0Iq2FFvakHfUApFwSVSZNwq3T9FvaMuuaQCC5sSaKwaGTUvNyNrDYxxWXrfUFrDGVLv9pEk62gc5rNdHVcoaanhf3AW4+wZGIyeFofFsidh5UjYYF9jdV/vnl6TwripBexTUgh0778bY3mxp7mSFqVNSY7Sou6eQPBSUm6xMifzUC5x5TLIX0qtGslmEDOlGWqaK6G06+UILF7eBZANaW9d2sswTQ050gztFlFeRrt2aR1vWw/kLo5nHK88MtE1cMSbrHuj62TWpMWdpbXmFej90OpQ824ykOShz+OJ0cB4Y1IysaZkVe+IUYKzC2yeeKchYNsNt+gcHUTHzXi046aTUNMeVoiwq4kgJG+06XxrxWfzuHfc+CXLlLWqOmp28PnkfM6WM5gryaYFj/hvQ031xiH9Q+hoDL5xn+CVEIMyI6SEOiVeKY586gSaEqm2GurA4NdTMOlGtahnOxWcbZtQAXvUdOTngn+0qAXso0mPSx+lsUCNMkdquRXYF+oRHemXSuIk1rTl2RG0CeW5kqcpRIFurkngVTMOfwzfgO4vZVxjbrnq5KxEU8mB/aOQvaisOk3kbZ2BKzc9rgVUXOxDZ9eANP55onGGBTTCjpfZnbrQOreJG57g8sXQTKj85asLic4fCP9pGP4/Q+l4Ea85FAEkQ8C3+UGD/xLpLO/3LnSSjXzoxTEncPDIJu+vj0ju3q0GaXgvCRCCxMvEEASDlEuqGvsGWzMBTu5iEk9Ekv9kYKHrVUBtkD2/s7rBd79OEAqlq5RoZcHizWPMsIwulZ4uz5cKGQdSay6tS7sc/7oZLUS/qIQcuqDuiwhcoiYtx22ttCU37qiRz8/kfh3XuyiUbNBTPHzhDovDPyJQRfBQCQAA\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var collapsedIcon = 'bemswvc';
export var container = 'bemswv0';
export var divider = 'bemswv2';
export var dividerContainer = 'bemswv1';
export var link = 'bemswv8';
export var linkPreview = 'bemswva';
export var linkPreviewContainer = 'bemswv9';
export var linkPreviewRenderer = 'bemswvb';
export var linksContainer = 'bemswv6';
export var linksTitles = 'bemswv7';
export var showButton = 'bemswv5';
export var title = 'bemswv4';
export var titleLine = 'bemswv3';